import { Component } from '@angular/core';

@Component({
  selector: 'app-support-hzn',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class HorizonSupportComponent {
  constructor() {}
}
