import { AfterViewInit, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUsModalComponent } from '@app/root/components/contact-us-modal/contact-us-modal.component';
import { LoginLayoutComponent } from '@app/root/components/login-layout/login-layout.component';
import { PassFormComponent } from '@app/root/components/pass-form/pass-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth.service';
import { FeatureFlagsService } from '@services/feature-flags.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, SharedModule, LoginLayoutComponent, PassFormComponent],
  templateUrl: './login.component.html',
})
export class LoginComponent implements AfterViewInit {
  readonly destroyRef = inject(DestroyRef);
  readonly authService = inject(AuthService);
  readonly featureFlagsService = inject(FeatureFlagsService);
  readonly modalService = inject(NgbModal);
  readonly route = inject(ActivatedRoute);
  readonly router = inject(Router);

  contactFormEnabled = this.featureFlagsService.select('contact-form-enabled');
  error = signal('');
  success = signal('');
  isSending = signal(false);
  username = signal(this.route.snapshot.queryParamMap.get('name') ?? '');
  password = signal(this.route.snapshot.queryParamMap.get('pass') ?? '');
  showConfirm = signal(false);
  resetCode = signal(this.route.snapshot.queryParamMap.get('code') ?? '');
  showReset = signal(this.route.snapshot.queryParamMap.get('reset') === '1');

  ngAfterViewInit() {
    if (this.username() !== '' && this.password() !== '') {
      this.onLogin();
    }
  }

  async onLogin() {
    this.error.set('');
    this.isSending.set(true);
    const result = await this.authService.login(this.username(), this.password());
    this.isSending.set(false);
    if (result.error) {
      this.error.set(result.error);
    } else if (result.nextStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      this.showConfirm.set(true);
    }
  }

  showContactForm() {
    this.modalService.open(ContactUsModalComponent, { backdrop: 'static', centered: true });
  }

  async confirmPass(password: string) {
    this.error.set('');
    this.isSending.set(true);
    const result = await this.authService.confirmPass(this.username(), password);
    this.isSending.set(false);
    if (result.error) {
      this.error.set(result.error);
    }
  }

  resetPass(password: string) {
    this.error.set('');
    this.isSending.set(true);
    this.authService
      .resetPassword(this.username(), password, this.resetCode())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isSending.set(false);
          this.showReset.set(false);
          this.success.set('Password was changed. Now you can login.');
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        },
        error: err => {
          this.error.set(err.error);
          this.isSending.set(false);
        },
      });
  }
}
