import { Component, HostBinding, input } from '@angular/core';

@Component({
  selector: 'app-login-layout',
  standalone: true,
  templateUrl: './login-layout.component.html',
})
export class LoginLayoutComponent {
  @HostBinding() class = 'login-page';
  header = input.required<string>();
}
