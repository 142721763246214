<h5 class="ubuntu400 cardHdr">User Information</h5>
<div class="pinkHr">
  <div class="card">
    <div class="card-body ng-cloak" style="padding-bottom: 20px">
      <div class="container-lg container-fluid padBottomSm">
        <div class="row">
          <p class="overflow-visible noMarginBotton font-14">NCPDP ID</p>
        </div>
        <div class="row">
          <p class="overflow-visible boldMe font-12">{{ pharmacy?.ncpdp }}</p>
        </div>
        <div class="row"></div>
      </div>
      <div class="container-fluid padBottomSm">
        <div class="row">
          <p class="overflow-visible noMarginBotton font-14">PHARMACY NAME</p>
        </div>
        <div class="row">
          <p class="overflow-visible boldMe font-12">{{ pharmacy?.name }}</p>
        </div>
        <div class="row"></div>
      </div>
      <div class="container-fluid padBottomSm">
        <div class="row">
          <p class="overflow-visible noMarginBotton font-14">ADDRESS</p>
        </div>
        <div class="row">
          <p class="overflow-visible boldMe font-12">{{ pharmacy?.addressone }}</p>
        </div>
        <div class="row"></div>
      </div>
      <div class="container-fluid padBottomSm">
        <div class="row">
          <ul class="citystate">
            <li>
              <p class="overflow-visible noMarginBotton font-14">CITY&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </li>
            <li>
              <p class="overflow-visible noMarginBotton font-14">STATE&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </li>
            <li>
              <p class="overflow-visible noMarginBotton font-14">ZIP&nbsp;&nbsp;</p>
            </li>
          </ul>
        </div>
        <div class="row">
          <ul class="citystate">
            <li>
              <p class="overflow-visible boldMe font-12">{{ pharmacy?.city }}&nbsp;&nbsp;&nbsp;</p>
            </li>
            <li>
              <p class="overflow-visible boldMe font-12">{{ pharmacy?.state }}&nbsp;&nbsp;</p>
            </li>
            <li>
              <p class="overflow-visible boldMe font-12">{{ pharmacy?.zip }}&nbsp;&nbsp;</p>
            </li>
          </ul>
        </div>
        <div class="row"></div>
      </div>
      <div class="container-fluid padBottomSm">
        <div class="row">
          <p class="overflow-visible noMarginBotton font-14">
            EMAIL ADDRESS
            <a
              style="padding-left: 5px"
              class="btn-link peptoPink txtUnderline font-12"
              data-toggle="modal"
              data-target="#changeEmailModal"
            >
              Edit
            </a>
          </p>
        </div>
        <div class="row">
          <p class="overflow-visible boldMe noMarginBotton font-12">{{ pharmacy?.email }}</p>
        </div>
        <div class="row"></div>
      </div>
      <div class="container-fluid padBottomSm">
        <div class="row">
          <p class="overflow-visible noMarginBotton font-14">BRIGHTSCRIP USER SINCE</p>
        </div>
        <div class="row">
          <p class="overflow-visible boldMe font-12">SINCE DATE</p>
        </div>
        <div class="row"></div>
      </div>
      <a href="#" class="aTextLink elementCenter font-14" style="padding-bottom: 20px" data-toggle="modal" data-target="#changePassModal"
        >Change Password</a
      >
    </div>
  </div>
</div>

<div class="modal" id="changePassModal" role="dialog" aria-labelledby="changePassModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Choose your avatar.</h3>
      </div>
      <div class="modal-body">
        <div class="container" style="width: 100%">
          <div class="row">
            <div class="col-md-12">
              <div class="alert alert-success" ng-if="success == 1">Success</div>

              <div ng-show="success == 0">
                <form ng-submit="changePassword()">
                  <div class="container-fluid padBottomSm">
                    <div class="row padBottomSm">
                      <div class="col-md-12">
                        <h4 class="elementCenter">Change User Password</h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p>New Password</p>
                      </div>
                    </div>
                    <div class="row padBottomSm">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control" ng-model="passwordInfo.newPass" required />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label>Confirm New Password </label>
                      </div>
                    </div>
                    <div class="row padBottomSm">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control" ng-model="passwordInfo.newConfirm" required />
                        </div>
                      </div>
                    </div>
                    <div class="row padBottomSm">
                      <div class="col-md-6">
                        <button type="submit" class="btn btn-sm-1" ng-disabled="isPasswordValid()">CHANGE PASSWORD</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="changeEmailModal" role="dialog" aria-labelledby="changeEmailModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <form class="regPanel" ng-submit="updateEmail()">
          <div class="container-fluid padBottomSm">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 col-centered">
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" ng-click="refreshPharmacyPage()"></button>
              </div>
            </div>
            <div class="row padBottomSm">
              <div class="col-md-12">
                <h4 class="elementCenter">Change User Email</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>New Email</p>
              </div>
            </div>
            <div class="row padBottomSm">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" name="email" ng-model="newemail" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Confirm New Email </label>
              </div>
            </div>
            <div class="row padBottomSm">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" name="repeatemail" ng-model="repeatnewemail" required />
                </div>
              </div>
            </div>
            <div class="row padBottomSm">
              <div class="col-md-6">
                <button active="current_user.password == currentPass" type="submit" class="button button--primary">CONFIRM</button>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn button button--default" data-dismiss="modal">CANCEL</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
