import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@ngx-formly/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
const _c0 = ["fieldTypeTemplate"];
function FormlyWrapperAddons_ng_template_0_div_1_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.props.addonLeft.class);
  }
}
function FormlyWrapperAddons_ng_template_0_div_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.props.addonLeft.text);
  }
}
function FormlyWrapperAddons_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function FormlyWrapperAddons_ng_template_0_div_1_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.addonLeftClick($event));
    });
    i0.ɵɵtemplate(1, FormlyWrapperAddons_ng_template_0_div_1_i_1_Template, 1, 1, "i", 5)(2, FormlyWrapperAddons_ng_template_0_div_1_span_2_Template, 2, 1, "span", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("input-group-btn", ctx_r1.props.addonLeft.onClick);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonLeft.class);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonLeft.text);
  }
}
function FormlyWrapperAddons_ng_template_0_div_4_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.props.addonRight.class);
  }
}
function FormlyWrapperAddons_ng_template_0_div_4_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.props.addonRight.text);
  }
}
function FormlyWrapperAddons_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function FormlyWrapperAddons_ng_template_0_div_4_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.addonRightClick($event));
    });
    i0.ɵɵtemplate(1, FormlyWrapperAddons_ng_template_0_div_4_i_1_Template, 1, 1, "i", 5)(2, FormlyWrapperAddons_ng_template_0_div_4_span_2_Template, 2, 1, "span", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("input-group-btn", ctx_r1.props.addonRight.onClick);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonRight.class);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonRight.text);
  }
}
function FormlyWrapperAddons_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, FormlyWrapperAddons_ng_template_0_div_1_Template, 3, 4, "div", 3);
    i0.ɵɵelementContainer(2, null, 1);
    i0.ɵɵtemplate(4, FormlyWrapperAddons_ng_template_0_div_4_Template, 3, 4, "div", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("has-validation", ctx_r1.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonLeft);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.props.addonRight);
  }
}
class FormlyWrapperAddons extends FieldWrapper {
  constructor(hostContainerRef) {
    super();
    this.hostContainerRef = hostContainerRef;
  }
  set content(templateRef) {
    if (templateRef && this.hostContainerRef) {
      this.hostContainerRef.createEmbeddedView(templateRef);
    }
  }
  addonRightClick($event) {
    this.props.addonRight.onClick?.(this.field, $event);
  }
  addonLeftClick($event) {
    this.props.addonLeft.onClick?.(this.field, $event);
  }
}
FormlyWrapperAddons.ɵfac = function FormlyWrapperAddons_Factory(t) {
  return new (t || FormlyWrapperAddons)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
FormlyWrapperAddons.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyWrapperAddons,
  selectors: [["formly-wrapper-addons"]],
  viewQuery: function FormlyWrapperAddons_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], ["fieldComponent", ""], [1, "input-group"], ["class", "input-group-text", 3, "input-group-btn", "click", 4, "ngIf"], [1, "input-group-text", 3, "click"], [3, "ngClass", 4, "ngIf"], [4, "ngIf"], [3, "ngClass"]],
  template: function FormlyWrapperAddons_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyWrapperAddons_ng_template_0_Template, 5, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgIf, i1.NgClass],
  styles: ["formly-wrapper-form-field .input-group-btn{cursor:pointer}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyWrapperAddons, [{
    type: Component,
    args: [{
      selector: 'formly-wrapper-addons',
      encapsulation: ViewEncapsulation.None,
      template: "<ng-template #fieldTypeTemplate>\n  <div class=\"input-group\" [class.has-validation]=\"showError\">\n    <div\n      class=\"input-group-text\"\n      *ngIf=\"props.addonLeft\"\n      [class.input-group-btn]=\"props.addonLeft.onClick\"\n      (click)=\"addonLeftClick($event)\"\n    >\n      <i [ngClass]=\"props.addonLeft.class\" *ngIf=\"props.addonLeft.class\"></i>\n      <span *ngIf=\"props.addonLeft.text\">{{ props.addonLeft.text }}</span>\n    </div>\n    <ng-container #fieldComponent></ng-container>\n    <div\n      class=\"input-group-text\"\n      *ngIf=\"props.addonRight\"\n      [class.input-group-btn]=\"props.addonRight.onClick\"\n      (click)=\"addonRightClick($event)\"\n    >\n      <i [ngClass]=\"props.addonRight.class\" *ngIf=\"props.addonRight.class\"></i>\n      <span *ngIf=\"props.addonRight.text\">{{ props.addonRight.text }}</span>\n    </div>\n  </div>\n</ng-template>\n",
      styles: ["formly-wrapper-form-field .input-group-btn{cursor:pointer}\n"]
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    content: [{
      type: ViewChild,
      args: ['fieldTypeTemplate', {
        static: true
      }]
    }]
  });
})();
function addonsExtension(field) {
  if (!field.props || field.wrappers && field.wrappers.indexOf('addons') !== -1) {
    return;
  }
  if (field.props.addonLeft || field.props.addonRight) {
    field.wrappers = [...(field.wrappers || []), 'addons'];
  }
}
class FormlyBootstrapAddonsModule {}
FormlyBootstrapAddonsModule.ɵfac = function FormlyBootstrapAddonsModule_Factory(t) {
  return new (t || FormlyBootstrapAddonsModule)();
};
FormlyBootstrapAddonsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapAddonsModule
});
FormlyBootstrapAddonsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyModule.forChild({
    wrappers: [{
      name: 'addons',
      component: FormlyWrapperAddons
    }],
    extensions: [{
      name: 'addons',
      extension: {
        postPopulate: addonsExtension
      }
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapAddonsModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyWrapperAddons],
      imports: [CommonModule, ReactiveFormsModule, FormlyModule.forChild({
        wrappers: [{
          name: 'addons',
          component: FormlyWrapperAddons
        }],
        extensions: [{
          name: 'addons',
          extension: {
            postPopulate: addonsExtension
          }
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapAddonsModule };
