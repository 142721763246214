<div class="app-toasts">
  <ngb-toast
    *ngFor="let toast of toasts$ | async"
    aria-atomic="true"
    [attr.aria-live]="toast.ariaLive"
    [autohide]="toast.autohide ?? true"
    [delay]="toast.delay ?? 500"
    [class]="toast.classname ?? ''"
    [header]="toast.header ?? ''"
    (click)="removeToast(toast.id)"
    (hidden)="removeToast(toast.id)"
    data-cy="toastMessage"
  >
    <ng-template [ngIf]="isTemplate(toast.textOrTemplate)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTemplate"></ng-template>
    </ng-template>
    <ng-template #text>{{ toast.textOrTemplate }}</ng-template>
  </ngb-toast>
</div>
