import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PbaEntryComponent } from '@app/pba/pba-entry.component';
import { AgreementComponent } from '@app/root/components/agreement/agreement.component';
import { LoginComponent } from '@app/root/components/login/login.component';
import { PassForgotComponent } from '@app/root/components/pass-forgot/pass-forgot.component';
import { TcComponent } from '@app/root/components/tc/tc.component';
import { TerminalEntryComponent } from '@app/root/components/terminal-entry/terminal-entry.component';
import { RoutesExplorer } from '@app/router-explorer';
import { AuthGuard } from '@services/guards/auth-guard';
import { TenantGuard } from '@services/tenant-guard.service';

export const NEW_ROUTES: Routes = [
  { path: '/portal' },
  { path: '/login' },
  { path: '/agreement' },
  { path: '/admin/pharmacy-score-card' },
  { path: '/admin/claim-event-monitor' },
  { path: '/admin/users' },
  { path: '/admin/query-workbench' },
  { path: '/admin/prescribers' },
  { path: '/admin/pharmacies' },
  { path: '/admin/pharmacies-map' },
  { path: '/admin/pa-requests' },
  { path: '/admin/appeal-requests' },
  { path: '/admin/privacy' },
  { path: '/admin/faq' },
  { path: '/admin/support' },
  { path: '/admin/bright-link-generator' },
  { path: '/admin/ncpdp-message-dashboard' },
  { path: '/admin/business-rules' },
  { path: '/admin/pharmacy' },
  { path: '/admin/settings' },
  { path: '/admin/payor-information' },
  { path: '/admin/era' },
  { path: '/admin/control-panel' },
  { path: '/admin/payment-statements' },
  { path: '/admin/claims-map' },
  { path: '/terminal' },
  { path: '/kiosk' },
  { path: '/kiosk/payment-requests' },
  { path: '/kiosk/payment-reversals' },
  { path: '/actions' },
];

const routes: Routes = [
  { path: 'analytics', redirectTo: '/admin/analytics', pathMatch: 'full' },
  { path: 'experience', redirectTo: '/admin/experience', pathMatch: 'full' },
  { path: 'ap', redirectTo: '/actions/patient', pathMatch: 'full' },
  { path: 'ap/:token', redirectTo: '/actions/patient/:token', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'retail',
    loadChildren: () => import('./retail/retail.module').then(m => m.RetailModule),
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'terminal',
    loadChildren: () => import('./terminal/terminal.module').then(m => m.TerminalModule),
  },
  {
    path: 'kiosk',
    loadChildren: () => import('./kiosk/kiosk.module').then(m => m.KioskModule),
  },
  {
    path: 'actions',
    loadChildren: () => import('./actions/actions-routes').then(m => m.ActionsRoutes),
  },
  {
    path: 'ta/:token',
    component: TerminalEntryComponent,
  },
  {
    path: 'ta',
    component: TerminalEntryComponent,
  },
  { path: 'cr', component: PbaEntryComponent },
  { path: 'CR', component: PbaEntryComponent },
  { path: 'Cr', component: PbaEntryComponent },
  { path: 'cR', component: PbaEntryComponent },
  { path: 'tc/:tenant', component: TcComponent },
  { path: 'TC/:tenant', component: TcComponent },
  { path: 'Tc/:tenant', component: TcComponent },
  { path: 'tC/:tenant', component: TcComponent },
  { path: 'agreement', component: AgreementComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/forgot', component: PassForgotComponent },
];

RoutesExplorer.addRoutes(routes);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !!sessionStorage.getItem('ROUTER_TRACE'),
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, TenantGuard],
})
export class AppRoutingModule {}
