import { Component, OnInit } from '@angular/core';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { UserService } from '@services/user.service';
import { IUser } from '@shared/models/user.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  pharmacy: IPharmacy;
  current_user: IUser;
  tempNcpdp = '0100381';

  constructor(
    private userService: UserService,
    private pharmacyService: PharmacyApiService,
  ) {}

  ngOnInit() {
    this.pharmacyService.getPharmacyByNcpdp(this.tempNcpdp).subscribe((p: IPharmacy) => {
      this.setPharmacy(p);
    });

    this.current_user = this.userService.getCurrentUser();
  }

  setPharmacy(pharmacy: IPharmacy) {
    this.pharmacy = pharmacy;
  }
}
