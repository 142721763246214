import { Injectable } from '@angular/core';

interface BlockedBin {
  bin: string;
}

@Injectable({
  providedIn: 'root',
})
export class BlockedInsuranceService {
  constructor() {}

  isBinBlocked(bin: string) {
    return this.getBlockedInsurance().some(blockedBin => blockedBin.bin === bin);
  }

  getBlockedInsurance(): BlockedBin[] {
    return [
      { bin: '000000' },
      { bin: '002858' },
      { bin: '003285' },
      { bin: '003385' },
      { bin: '003535' },
      { bin: '003558' },
      { bin: '003560' },
      { bin: '003585' },
      { bin: '003747' },
      { bin: '003858' },
      { bin: '004335' },
      { bin: '004336' },
      { bin: '004337' },
      { bin: '004366' },
      { bin: '004436' },
      { bin: '004578' },
      { bin: '004583' },
      { bin: '004626' },
      { bin: '004750' },
      { bin: '004758' },
      { bin: '004759' },
      { bin: '004915' },
      { bin: '005497' },
      { bin: '005647' },
      { bin: '005749' },
      { bin: '005946' },
      { bin: '005947' },
      { bin: '005948' },
      { bin: '005957' },
      { bin: '006858' },
      { bin: '007382' },
      { bin: '008019' },
      { bin: '008358' },
      { bin: '009574' },
      { bin: '009858' },
      { bin: '010868' },
      { bin: '011172' },
      { bin: '011230' },
      { bin: '011248' },
      { bin: '011552' },
      { bin: '011800' },
      { bin: '012089' },
      { bin: '012122' },
      { bin: '012189' },
      { bin: '012304' },
      { bin: '012312' },
      { bin: '012320' },
      { bin: '012353' },
      { bin: '012363' },
      { bin: '012543' },
      { bin: '012833' },
      { bin: '013337' },
      { bin: '013344' },
      { bin: '013550' },
      { bin: '014897' },
      { bin: '014906' },
      { bin: '015178' },
      { bin: '035859' },
      { bin: '046269' },
      { bin: '052700' },
      { bin: '059471' },
      { bin: '097204' },
      { bin: '123530' },
      { bin: '235303' },
      { bin: '310014' },
      { bin: '400002' },
      { bin: '400023' },
      { bin: '462101' },
      { bin: '533389' },
      { bin: '563485' },
      { bin: '587426' },
      { bin: '600042' },
      { bin: '600248' },
      { bin: '600428' },
      { bin: '600471' },
      { bin: '600482' },
      { bin: '600604' },
      { bin: '601197' },
      { bin: '601577' },
      { bin: '603286' },
      { bin: '610011' },
      { bin: '610013' },
      { bin: '610014' },
      { bin: '610020' },
      { bin: '610029' },
      { bin: '610053' },
      { bin: '610079' },
      { bin: '610083' },
      { bin: '610097' },
      { bin: '610111' },
      { bin: '610118' },
      { bin: '610211' },
      { bin: '610219' },
      { bin: '610241' },
      { bin: '610415' },
      { bin: '610455' },
      { bin: '610468' },
      { bin: '610469' },
      { bin: '610473' },
      { bin: '610475' },
      { bin: '610494' },
      { bin: '610502' },
      { bin: '610575' },
      { bin: '610593' },
      { bin: '610623' },
      { bin: '610632' },
      { bin: '610649' },
      { bin: '610652' },
      { bin: '610715' },
      { bin: '611001' },
      { bin: '629999' },
      { bin: '640014' },
      { bin: '703071' },
      { bin: '910097' },
    ];
  }
}
