import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AUTH_REQUIRED } from '@app/tokens';
import { AuthService } from '@services/auth.service';
import { EMPTY, Observable, from, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  intercept(req: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
    return from(this.handleRequest(req)).pipe(switchMap(request => (request ? next.handle(request) : EMPTY)));
  }

  async handleRequest(request: HttpRequest<{}>): Promise<HttpRequest<{}> | null> {
    const authRequired = request.context.get(AUTH_REQUIRED);

    if (authRequired) {
      const accessToken = await this.authService.fetchAccessToken();
      if (!accessToken) {
        this.authService.logout('HttpClientAccessToken');
        return null;
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return request;
  }
}
