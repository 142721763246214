import { Component } from '@angular/core';

@Component({
  selector: 'app-support-alm',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class AlmirallSupportComponent {
  constructor() {}
}
