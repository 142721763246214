import { Component, TemplateRef } from '@angular/core';
import { Toast, ToastService } from '@app/root/providers/toast-service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent {
  toasts$: BehaviorSubject<Toast[]>;

  constructor(private toastService: ToastService) {
    this.toasts$ = toastService.toasts$;
  }

  removeToast(id: number) {
    this.toastService.remove(id);
  }

  isTemplate(value: unknown): value is TemplateRef<any> {
    return value instanceof TemplateRef;
  }
}
