<app-login-layout header="Password reset">
  <p>Provide your username or email and we'll send you an email with instructions to reset your password.</p>

  @if (error()) {
    <div class="alert alert-danger col-10 mt-4" role="alert" data-cy="alert">
      <div class="alert-message"><strong class="pe-1">There's been an issue: </strong>{{ error() }}</div>
    </div>
  }

  @if (success()) {
    <div class="alert alert-success col-10 mt-4" role="alert" data-cy="alert">
      <div class="alert-message">
        <strong class="pe-1">Success! </strong>
        Your password reset request was received. Please check your email to reset your password.
      </div>
    </div>
  }

  <form (ngSubmit)="onSend()">
    <div class="mt-4 mb-3 login-field">
      <label class="form-label" for="username">Username or Email address</label>
      <input type="text" [(ngModel)]="username" name="username" id="username" required class="form-control" />
    </div>
    <chitin-button type="submit" data-cy="loginBtn" label="Reset password" [isLoading]="isSending()" [disabled]="!username().length" />
  </form>

  <a routerLink="/login" class="mt-4 d-block forgot-password-link" data-cy="forgotPassword">Back to login</a>
</app-login-layout>
