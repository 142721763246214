import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
import { FormlyBootstrapInputModule } from '@ngx-formly/bootstrap/input';
import { FormlyBootstrapTextAreaModule } from '@ngx-formly/bootstrap/textarea';
import { FormlyBootstrapRadioModule } from '@ngx-formly/bootstrap/radio';
import { FormlyBootstrapCheckboxModule } from '@ngx-formly/bootstrap/checkbox';
import { FormlyBootstrapMultiCheckboxModule } from '@ngx-formly/bootstrap/multicheckbox';
import { FormlyBootstrapSelectModule } from '@ngx-formly/bootstrap/select';
import { FormlyBootstrapAddonsModule } from '@ngx-formly/bootstrap/addons';
class FormlyBootstrapModule {}
FormlyBootstrapModule.ɵfac = function FormlyBootstrapModule_Factory(t) {
  return new (t || FormlyBootstrapModule)();
};
FormlyBootstrapModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapModule
});
FormlyBootstrapModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[FormlyBootstrapFormFieldModule, FormlyBootstrapInputModule, FormlyBootstrapTextAreaModule, FormlyBootstrapRadioModule, FormlyBootstrapCheckboxModule, FormlyBootstrapMultiCheckboxModule, FormlyBootstrapSelectModule, FormlyBootstrapAddonsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapModule, [{
    type: NgModule,
    args: [{
      imports: [FormlyBootstrapFormFieldModule, FormlyBootstrapInputModule, FormlyBootstrapTextAreaModule, FormlyBootstrapRadioModule, FormlyBootstrapCheckboxModule, FormlyBootstrapMultiCheckboxModule, FormlyBootstrapSelectModule, FormlyBootstrapAddonsModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of bootstrap
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapModule };
