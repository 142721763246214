<p class="pt-3">Please enter a new password. Make sure it has all of the following:</p>
<ul>
  @for (item of validations(); track $index) {
    <li>{{ item }}</li>
  }
</ul>

<form (ngSubmit)="onSubmit()">
  <div class="mt-4 mb-3 login-field">
    <label class="form-label" for="password">New password</label>
    <div class="input-group mb-3">
      <input [type]="passwordType()" [(ngModel)]="password" name="password" id="password" data-cy="newPassword" class="form-control" />
      <button (click)="showPassword.set(!showPassword())" class="btn btn-light shadow-none" type="button">
        @if (showPassword()) {
          <i class="fa-solid fa-eye-slash" title="Hide password"></i>
        } @else {
          <i class="fa-solid fa-eye" title="Show password"></i>
        }
      </button>
    </div>
  </div>
  <div class="mb-4 login-field">
    <label class="form-label" for="passwordCheck">Confirm password</label>
    <input
      type="password"
      [(ngModel)]="passwordCheck"
      name="passwordCheck"
      id="passwordCheck"
      data-cy="newPasswordCheck"
      class="form-control"
    />
  </div>
  <chitin-button
    type="submit"
    data-cy="loginBtn"
    [label]="isReset() ? 'Reset password' : 'Create password'"
    [disabled]="!!validations().length"
    [isLoading]="isSending()"
  />
</form>
