import { Component } from '@angular/core';
import { faqItems } from '@app/root/components/faq/faq-items';
import { TenantService } from '@services/tenant.service';
import { BaseComponent } from '@shared/components/base-component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent extends BaseComponent {
  tenantId = this.tenantService.getCurrentTenantId();

  items = faqItems;

  constructor(private tenantService: TenantService) {
    super();
    this.subscribe(this.tenantService.activeTenantChanged, () => {
      this.tenantId = this.tenantService.getCurrentTenantId();
    });
  }
}
