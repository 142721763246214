import { Injectable } from '@angular/core';
import { CallInformation, ICallInformation } from '@shared/models/call-information.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '@services/url.service';
import { AuthService } from '@services/auth.service';
import Utils from '@shared/providers/utils';

@Injectable({
  providedIn: 'root',
})
export class CallsApiService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private authService: AuthService,
  ) {}

  start(callInfo: CallInformation): Observable<any> {
    const _callInfo = Utils.copyObject(callInfo);
    return this.upsertCall(_callInfo);
  }

  private upsertCall(callInfo: CallInformation): Observable<any> {
    return this.http.put(this.urlService.INBOUND_CALLS_URL, callInfo);
  }

  end(callInfo: CallInformation): Observable<any> {
    return this.upsertCall(callInfo);
  }

  search(fromDate: Date, includeUserName = false): Observable<ICallInformation[]> {
    const body: Record<string, any> = {
      begin_call_start: fromDate,
    };

    if (includeUserName) {
      body['username'] = this.authService.getCurrentUser()?.username;
    }

    return this.http.post<ICallInformation[]>(this.urlService.INBOUND_CALLS_SEARCH_URL, body);
  }
}
