import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { SelectControlValueAccessor, ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i4 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
import { take } from 'rxjs/operators';
function FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const opt_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngValue", opt_r1.value)("disabled", opt_r1.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", opt_r1.label, " ");
  }
}
function FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_ng_template_2_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = ctx.$implicit;
    i0.ɵɵproperty("ngValue", child_r2.value)("disabled", child_r2.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", child_r2.label, " ");
  }
}
function FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "optgroup", 9);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_ng_template_2_option_1_Template, 2, 3, "option", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const opt_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("label", opt_r1.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", opt_r1.group);
  }
}
function FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_option_1_Template, 2, 3, "option", 7)(2, FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_ng_template_2_Template, 2, 2, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const opt_r1 = ctx.$implicit;
    const optgroup_r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !opt_r1.group)("ngIfElse", optgroup_r3);
  }
}
function FormlyFieldSelect_ng_template_0_select_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_select_0_ng_container_1_ng_container_1_Template, 4, 2, "ng-container", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const opts_r4 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", opts_r4);
  }
}
function FormlyFieldSelect_ng_template_0_select_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "select", 4);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_select_0_ng_container_1_Template, 2, 1, "ng-container", 5);
    i0.ɵɵpipe(2, "formlySelectOptions");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("is-invalid", ctx_r4.showError);
    i0.ɵɵproperty("formControl", ctx_r4.formControl)("compareWith", ctx_r4.props.compareWith)("formlyAttributes", ctx_r4.field);
    i0.ɵɵattribute("aria-describedby", ctx_r4.id + "-formly-validation-error")("aria-invalid", ctx_r4.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 11, i0.ɵɵpipeBind2(2, 8, ctx_r4.props.options, ctx_r4.field)));
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngValue", undefined);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r4.props.placeholder);
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const opt_r6 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngValue", opt_r6.value)("disabled", opt_r6.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", opt_r6.label, " ");
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_ng_template_2_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r7 = ctx.$implicit;
    i0.ɵɵproperty("ngValue", child_r7.value)("disabled", child_r7.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", child_r7.label, " ");
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "optgroup", 9);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_ng_template_2_option_1_Template, 2, 3, "option", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const opt_r6 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("label", opt_r6.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", opt_r6.group);
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_option_1_Template, 2, 3, "option", 7)(2, FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_ng_template_2_Template, 2, 2, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const opt_r6 = ctx.$implicit;
    const optgroup_r8 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !opt_r6.group)("ngIfElse", optgroup_r8);
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_ng_container_1_Template, 4, 2, "ng-container", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const opts_r9 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", opts_r9);
  }
}
function FormlyFieldSelect_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "select", 11);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_template_0_ng_template_1_option_1_Template, 2, 2, "option", 12)(2, FormlyFieldSelect_ng_template_0_ng_template_1_ng_container_2_Template, 2, 1, "ng-container", 5);
    i0.ɵɵpipe(3, "formlySelectOptions");
    i0.ɵɵpipe(4, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("is-invalid", ctx_r4.showError);
    i0.ɵɵproperty("formControl", ctx_r4.formControl)("compareWith", ctx_r4.props.compareWith)("formlyAttributes", ctx_r4.field);
    i0.ɵɵattribute("aria-describedby", ctx_r4.id + "-formly-validation-error")("aria-invalid", ctx_r4.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r4.props.placeholder);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 12, i0.ɵɵpipeBind2(3, 9, ctx_r4.props.options, ctx_r4.field)));
  }
}
function FormlyFieldSelect_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyFieldSelect_ng_template_0_select_0_Template, 4, 13, "select", 3)(1, FormlyFieldSelect_ng_template_0_ng_template_1_Template, 5, 14, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const singleSelect_r10 = i0.ɵɵreference(2);
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r4.props.multiple)("ngIfElse", singleSelect_r10);
  }
}
class FormlyFieldSelect extends FieldType {
  constructor(ngZone, hostContainerRef) {
    super(hostContainerRef);
    this.ngZone = ngZone;
    this.defaultOptions = {
      props: {
        compareWith(o1, o2) {
          return o1 === o2;
        }
      }
    };
  }
  // workaround for https://github.com/angular/angular/issues/10010
  /**
   * TODO: Check if this is still needed
   */
  set selectAccessor(s) {
    if (!s) {
      return;
    }
    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }
    s.writeValue = value => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
          if (id !== s._idCounter && s._getOptionId(value) === null && s._elementRef.nativeElement.selectedIndex !== -1) {
            writeValue(value);
          }
        });
      }
    };
  }
}
FormlyFieldSelect.ɵfac = function FormlyFieldSelect_Factory(t) {
  return new (t || FormlyFieldSelect)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
FormlyFieldSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldSelect,
  selectors: [["formly-field-select"]],
  viewQuery: function FormlyFieldSelect_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(SelectControlValueAccessor, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectAccessor = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], ["singleSelect", ""], ["optgroup", ""], ["class", "form-select", "multiple", "", 3, "formControl", "compareWith", "is-invalid", "formlyAttributes", 4, "ngIf", "ngIfElse"], ["multiple", "", 1, "form-select", 3, "formControl", "compareWith", "formlyAttributes"], [4, "ngIf"], [4, "ngFor", "ngForOf"], [3, "ngValue", "disabled", 4, "ngIf", "ngIfElse"], [3, "ngValue", "disabled"], [3, "label"], [3, "ngValue", "disabled", 4, "ngFor", "ngForOf"], [1, "form-select", 3, "formControl", "compareWith", "formlyAttributes"], [3, "ngValue", 4, "ngIf"], [3, "ngValue"]],
  template: function FormlyFieldSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldSelect_ng_template_0_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgIf, i2.SelectMultipleControlValueAccessor, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i1.NgForOf, i2.NgSelectOption, i2.ɵNgSelectMultipleOption, i2.SelectControlValueAccessor, i1.AsyncPipe, i4.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldSelect, [{
    type: Component,
    args: [{
      selector: 'formly-field-select',
      template: `
    <ng-template #fieldTypeTemplate>
      <select
        *ngIf="props.multiple; else singleSelect"
        class="form-select"
        multiple
        [formControl]="formControl"
        [compareWith]="props.compareWith"
        [class.is-invalid]="showError"
        [formlyAttributes]="field"
        [attr.aria-describedby]="id + '-formly-validation-error'"
        [attr.aria-invalid]="showError"
      >
        <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
          <ng-container *ngFor="let opt of opts">
            <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
              {{ opt.label }}
            </option>
            <ng-template #optgroup>
              <optgroup [label]="opt.label">
                <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                  {{ child.label }}
                </option>
              </optgroup>
            </ng-template>
          </ng-container>
        </ng-container>
      </select>

      <ng-template #singleSelect>
        <select
          class="form-select"
          [formControl]="formControl"
          [compareWith]="props.compareWith"
          [class.is-invalid]="showError"
          [formlyAttributes]="field"
          [attr.aria-describedby]="id + '-formly-validation-error'"
          [attr.aria-invalid]="showError"
        >
          <option *ngIf="props.placeholder" [ngValue]="undefined">{{ props.placeholder }}</option>
          <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
                {{ opt.label }}
              </option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
      </ng-template>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.ViewContainerRef
    }];
  }, {
    selectAccessor: [{
      type: ViewChild,
      args: [SelectControlValueAccessor]
    }]
  });
})();
class FormlyBootstrapSelectModule {}
FormlyBootstrapSelectModule.ɵfac = function FormlyBootstrapSelectModule_Factory(t) {
  return new (t || FormlyBootstrapSelectModule)();
};
FormlyBootstrapSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapSelectModule
});
FormlyBootstrapSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field']
    }, {
      name: 'enum',
      extends: 'select'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldSelect],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'select',
          component: FormlyFieldSelect,
          wrappers: ['form-field']
        }, {
          name: 'enum',
          extends: 'select'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapSelectModule, FormlyFieldSelect };
