import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from '@services/url.service';
import { AuthService } from '@services/auth.service';
import { ISurveillanceSettings, ISurveillanceSettingTyped } from '@shared/models/surveillance-settings';
import { DateService } from '@services/date.service';

@Injectable({
  providedIn: 'root',
})
export class SurveillanceSettingsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private urlService: UrlService,
    private dateService: DateService,
  ) {}

  /*
   * Example params:
   * ncpdp: 5719692
   * createdDate: 2019-02-25T17:57:01Z
   */
  getSettings(): Observable<ISurveillanceSettings> {
    const url = this.urlService.SURVEILLANCE_SETTINGS_URL;

    return this.http.post<ISurveillanceSettings>(url, {});
  }

  getRulesUsage(from_date: Date, to_date: Date): Observable<any> {
    const params = {
      offset_from_utc: this.dateService.getUtcOffset(),
      from_date: this.dateService.formatDateForApi(from_date),
      to_date: this.dateService.formatDateForApi(to_date),
    };
    const url = this.urlService.RULES_USAGE_URL;

    return this.http.post<any>(url, params);
  }

  saveSetting(rule: ISurveillanceSettingTyped): Observable<ISurveillanceSettings> {
    const url = this.urlService.SURVEILLANCE_SETTINGS_URL;

    return this.http.put<ISurveillanceSettings>(url, rule);
  }
}
