import { TenantIdType } from '@shared/models/tenant-id.type';

export type FaqItem = {
  title: string;
  body: string;
  tenant?: TenantIdType;
};

export const faqItems: FaqItem[] = [
  {
    title: 'Why am I not able to request codes?',
    body: "Codes can be requested once a week. If codes have already been released to this user account in the last week, you'll need to wait until the <b class='text-success'>READY</b> indicator is shown under Days Until Next Release in the Request Codes section.",
  },
  {
    title: 'When I request codes, where will they be sent?',
    body: 'Requested codes will be sent to the email address listed in the User Information section.',
  },
  {
    title: 'How do I change my password?',
    body: 'Your password can be changed by clicking the Change Password link in the User Information section and following the prompts.',
  },
  {
    title: 'How do I change which email address codes are sent?',
    body: "Click on 'edit email' in the User Information section and follow the prompts.",
  },
  {
    title: 'Are codes transferable?',
    body: 'Coupon codes are not transferable and can only be used by the pharmacy (specific NCPDP) that initially received the coupon from brightscrip.',
  },
  {
    title: 'What happens if I get a claim reject?',
    body: 'Claims may reject for codes 75,76,78,88,608 and MR, which may require further action by the Pharmacy and HCP. Please refer to the rejection message for details.',
  },
  {
    title: 'Troubleshooting?',
    body: "If you're having trouble to obtain your codes through brightscrip, please contact us for assistance by clicking the Support button on the right side of your browser window.",
  },
];
