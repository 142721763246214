import { Injectable } from '@angular/core';

const DEFAULT_RETRY_COUNT = 30;
const DEFAULT_DELAY_MS = 1000;

@Injectable({
  providedIn: 'root',
})
export class RetryService {
  async retry<T>(fn: Function, retryCount = DEFAULT_RETRY_COUNT, delayMs = DEFAULT_DELAY_MS): Promise<T> {
    let retries = 1;
    let success = false;
    while (!success && retries <= retryCount) {
      const result = await fn().catch(async (error: Error) => {
        console.log('Error', error);
        console.log('Retry Count', retries);
        retries++;
        if (retries > retryCount) {
          throw error;
        } else {
          await this.sleep(delayMs);
        }
      });
      if (result) {
        success = true;
        return result;
      }
    }
    return await fn();
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
