import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { BaseService } from '@services/base.service';
import { UrlService } from '@services/url.service';
import { LoadSequence } from '@shared/models/load-sequence.model';
import { TenantIdType } from '@shared/models/tenant-id.type';

interface ImportDataResponse {
  load_sequence: LoadSequence;
}

@Injectable({
  providedIn: 'root',
})
export class EtlImportService extends BaseService {
  private sender = '';

  constructor(
    private urlService: UrlService,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    super();
    this.authService.authStatusChanged.subscribe(authenticated => this.setSender(authenticated));
  }

  isSenderEmpty(): boolean {
    return !this.sender;
  }

  importFile(sender: string | null, s3FileName: string, s3BucketName: string, route_id: string, tenantId?: TenantIdType) {
    const body = {
      bucket: s3BucketName,
      filename: s3FileName,
      sender: sender == null ? this.sender : sender,
      route_id: route_id,
      username: this.authService.getCurrentUser()?.username,
    };
    let url = this.urlService.ETL_IMPORT_FILE_URL;
    if (tenantId) {
      url = url.replace('{tenant}', tenantId);
    }
    return this.http.post<ImportDataResponse>(url, body);
  }

  private setSender(authenticated: boolean) {
    if (authenticated) {
      this.sender = this.authService.getCurrentUser()?.ncpdps?.[0] ?? '';
    } else {
      this.sender = '';
    }
  }
}
