import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
function FormlyFieldInput_ng_template_0_input_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("is-invalid", ctx_r0.showError);
    i0.ɵɵproperty("type", ctx_r0.type)("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
    i0.ɵɵattribute("aria-describedby", ctx_r0.id + "-formly-validation-error")("aria-invalid", ctx_r0.showError);
  }
}
function FormlyFieldInput_ng_template_0_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("is-invalid", ctx_r0.showError);
    i0.ɵɵproperty("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
    i0.ɵɵattribute("aria-describedby", ctx_r0.id + "-formly-validation-error")("aria-invalid", ctx_r0.showError);
  }
}
function FormlyFieldInput_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyFieldInput_ng_template_0_input_0_Template, 1, 7, "input", 2)(1, FormlyFieldInput_ng_template_0_ng_template_1_Template, 1, 6, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
  }
  if (rf & 2) {
    const numberTmp_r2 = i0.ɵɵreference(2);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.type !== "number")("ngIfElse", numberTmp_r2);
  }
}
class FormlyFieldInput extends FieldType {
  get type() {
    return this.props.type || 'text';
  }
}
FormlyFieldInput.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldInput_BaseFactory;
  return function FormlyFieldInput_Factory(t) {
    return (ɵFormlyFieldInput_BaseFactory || (ɵFormlyFieldInput_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldInput)))(t || FormlyFieldInput);
  };
})();
FormlyFieldInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldInput,
  selectors: [["formly-field-input"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], ["numberTmp", ""], ["class", "form-control", 3, "type", "formControl", "formlyAttributes", "is-invalid", 4, "ngIf", "ngIfElse"], [1, "form-control", 3, "type", "formControl", "formlyAttributes"], ["type", "number", 1, "form-control", 3, "formControl", "formlyAttributes"]],
  template: function FormlyFieldInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldInput_ng_template_0_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgIf, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i2.NumberValueAccessor],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldInput, [{
    type: Component,
    args: [{
      selector: 'formly-field-input',
      template: `
    <ng-template #fieldTypeTemplate>
      <input
        *ngIf="type !== 'number'; else numberTmp"
        [type]="type"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [attr.aria-describedby]="id + '-formly-validation-error'"
        [attr.aria-invalid]="showError"
      />
      <ng-template #numberTmp>
        <input
          type="number"
          [formControl]="formControl"
          class="form-control"
          [formlyAttributes]="field"
          [class.is-invalid]="showError"
          [attr.aria-describedby]="id + '-formly-validation-error'"
          [attr.aria-invalid]="showError"
        />
      </ng-template>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyBootstrapInputModule {}
FormlyBootstrapInputModule.ɵfac = function FormlyBootstrapInputModule_Factory(t) {
  return new (t || FormlyBootstrapInputModule)();
};
FormlyBootstrapInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapInputModule
});
FormlyBootstrapInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    }, {
      name: 'string',
      extends: 'input'
    }, {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }, {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapInputModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldInput],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field']
        }, {
          name: 'string',
          extends: 'input'
        }, {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }, {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapInputModule, FormlyFieldInput };
