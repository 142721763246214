import { Injectable, signal } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AgreementForm {
  create() {
    return signal(cloneDeep(this.fields()));
  }

  private fields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            key: 'first_name',
            type: 'input',
            props: { label: 'First name', required: true },
          },
          {
            className: 'col-6',
            key: 'last_name',
            type: 'input',
            props: { label: 'Last name', required: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'email',
            type: 'input',
            props: { label: 'Email', disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'phone',
            type: 'phone',
            validators: {
              validation: ['phone'],
            },
            props: { label: 'Phone number', required: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'termsAccepted',
            type: 'checkbox',
            defaultValue: false,
            validators: {
              termsAccepted: {
                expression: (c: AbstractControl) => c.value,
                message: (error: any, field: FormlyFieldConfig) => 'You must accept these agreements to continue',
              },
            },
            props: {
              formCheck: 'inline',
              label:
                'I agree that Brightscrip c/o Monarch Speciality Group may send account messages to my provided phone number including SMS message for one-time PIN codes via calls, texts, or pre-recorded messages.',
              required: true,
            },
          },
        ],
      },
    ];
  }
}
