import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponRequestStateService } from '@services/coupon-request-state.service';

@Component({
  selector: 'app-pba-entry',
  template: '',
})
export class PbaEntryComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    let token: string = this.activatedRoute.snapshot.queryParamMap.get('t') ?? '';

    if (!token) {
      token = this.activatedRoute.snapshot.queryParamMap.get('T') ?? '';
    }

    if (!token) {
      token = this.activatedRoute.snapshot.queryParamMap.get('token') ?? '';
    }

    if (!token) {
      this.router.navigate([CouponRequestStateService.ERRORED_LINK_ROUTE]);
    } else {
      this.router.navigate(['/retail/coupon-request'], { queryParams: { token } });
    }
  }
}
