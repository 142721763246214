import { Injectable, OnDestroy } from '@angular/core';
import { CallsApiService } from '@services/calls-api.service';
import { CopayCodeRequestApiService } from '@services/copay-code-request-api.service';
import { CopayCodeRequestStateService } from '@services/copay-code-request-state.service';
import { LogService } from '@services/log.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { PharmacyUtilityService } from '@services/pharmacy-utility.service';
import { ProgramUtilityService } from '@services/program-utility.service';
import { TenantService } from '@services/tenant.service';
import { UserStoreService } from '@services/user-store.service';
import { CallInformation } from '@shared/models/call-information.model';
import { CopayCodeRequestFailures } from '@shared/models/copay-code-request-failures.enum';
import { CopayCodeRequestStatus } from '@shared/models/copay-code-request-status.type';
import { ICopayCodeRequest } from '@shared/models/copay-code-request.model';
import { ICopayInformation } from '@shared/models/copay-information.model';
import { NewPharmacy } from '@shared/models/new-pharmacy.model';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { IPrescriber } from '@shared/models/prescriber.model';
import { ProgramCodeAvailability } from '@shared/models/program-code-availability.model';
import { Program } from '@shared/models/program.model';
import Utils from '@shared/providers/utils';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

enum CallServiceErrorMessage {
  CallLimit = 'Your pharmacy has reached its call limit',
  DailyLimit = 'Your pharmacy has reached its daily limit',
  WeeklyLimit = 'Your pharmacy has reached its Weekly limit',
  CallAndDailyLimits = 'Your pharmacy has reached its call and daily limits',
  CallAndWeeklyLimits = 'Your pharmacy has reached its call and weekly limits',
  DailyAndWeeklyLimits = 'Your pharmacy has reached its daily and weekly limits',
  AllLimits = 'Your pharmacy has reached its call, daily, and weekly limits',
  CodeDispense = 'There was a problem dispensing the code',
}

enum CallServiceShortErrorMessage {
  WeeklyLimit = 'Reached Weekly Limit',
  DailyLimit = 'Reached Daily Limit',
}

@Injectable({
  providedIn: 'root',
})
export class CallService implements OnDestroy {
  currentCall = new BehaviorSubject<CallInformation | undefined>(undefined);

  pharmacy = new BehaviorSubject<IPharmacy | NewPharmacy | undefined>(undefined);
  prescriber = new BehaviorSubject<IPrescriber | undefined>(undefined);
  program = new BehaviorSubject<Program | undefined>(undefined);
  pharmacyAndProgram = new BehaviorSubject<[IPharmacy | NewPharmacy, Program] | undefined>(undefined);

  copayCodeRequest?: ICopayCodeRequest;
  lastCodeRequest = new BehaviorSubject<ICopayCodeRequest | undefined>(undefined);

  patientNumber = new BehaviorSubject<number>(1);

  percentDone = new BehaviorSubject<number>(0); // for timer in call bar
  // for green-red progress indicator behind timer in call bar
  callHasError = new BehaviorSubject<boolean>(false);

  isPharmacyBelowLimits = new BehaviorSubject<boolean>(false);
  isCurrentPatientSaved = new BehaviorSubject<boolean>(false);

  payorModalOpen = new BehaviorSubject<boolean>(false);

  patientAdded = new Subject<string>();

  numCodesAvailableToDispenseThisWeek = new BehaviorSubject<number>(0);
  numCodesAvailableToDispenseToday = new BehaviorSubject<number>(0);
  pharmacyHasAvailableCodes = new BehaviorSubject<boolean>(false);

  copayInfo = new BehaviorSubject<ICopayInformation | undefined>(undefined);

  errorMessage = new BehaviorSubject<CallServiceErrorMessage | undefined>(undefined);
  errorShort = '';
  formErrorMessage = '';

  isLoading = new BehaviorSubject<boolean>(false);

  currentStep: number;

  private callLimit: number;
  private serviceDestroyed = new Subject<void>();
  private wasLastRequestAResend = false;
  private didResendHaveError = false;

  constructor(
    private pharmacyApiService: PharmacyApiService,
    private copayCodeRequestStateService: CopayCodeRequestStateService,
    private copayCodeRequestApiService: CopayCodeRequestApiService,
    private pharmacyUtilityService: PharmacyUtilityService,
    private userStoreService: UserStoreService,
    private programUtilityService: ProgramUtilityService,
    private callsApiService: CallsApiService,
    private Log: LogService,
    private tenantService: TenantService,
  ) {
    this.observePharmacy();
    this.observeProgram();
    this.getAndObserveTenantForCallLimit();
  }

  ngOnDestroy() {
    this.serviceDestroyed.next();
    this.serviceDestroyed.complete();
  }

  private observePharmacy(): void {
    this.pharmacy.pipe(takeUntil(this.serviceDestroyed)).subscribe(newPharmacy => {
      this.pharmacyAndProgram.next([newPharmacy!, this.program.getValue()!]);
    });

    this.userStoreService
      .getPharmacies()
      .pipe(takeUntil(this.serviceDestroyed))
      .subscribe(pharmacies => this.pharmacy.next(pharmacies[0]));
  }

  private observeProgram(): void {
    this.program.pipe(takeUntil(this.serviceDestroyed)).subscribe(newProgram => {
      this.startCodeAvailabilityCheck().subscribe();
      this.setLastCodeRequestWhenWeeklyBatchProgram(newProgram!);
      this.pharmacyAndProgram.next([this.getPharmacy()!, newProgram!]);
    });
  }

  private getAndObserveTenantForCallLimit(): void {
    this.setCallLimitFromCurrentTenant();

    this.tenantService.activeTenantChanged.pipe(takeUntil(this.serviceDestroyed)).subscribe(_ => this.setCallLimitFromCurrentTenant());
  }

  private setCallLimitFromCurrentTenant(): void {
    this.callLimit = 3;
  }

  private isPharmacyNew(pharmacy?: IPharmacy): boolean {
    return this.pharmacyUtilityService.isPharmacyNew(pharmacy);
  }

  newCall(call: CallInformation): void {
    this.patientNumber.next(1);
    this.pharmacy.next(undefined);
    this.reset();
    this.currentCall.next(call);
    this.pharmacyApiService.getPharmacyByNcpdp(call.caller_id).subscribe(pharmacy => {
      this.pharmacy.next(pharmacy);
      this.startCodeRequest();
    });
  }

  reset(resetProgram = true): void {
    this.copayCodeRequest = undefined;
    this.numCodesAvailableToDispenseThisWeek.next(0);
    this.numCodesAvailableToDispenseToday.next(0);
    this.pharmacyHasAvailableCodes.next(false);
    this.callHasError.next(false);
    this.lastCodeRequest.next(undefined);
    this.resetErrorMessage();
    this.percentDone.next(0);
    this.copayInfo.next(undefined);
    if (resetProgram) {
      this.program.next(undefined);
    }
  }

  getPharmacy(): IPharmacy | undefined {
    return this.pharmacy.getValue();
  }

  getProgram(): Program | undefined {
    return this.program.getValue();
  }

  getCurrentCall(): CallInformation | undefined {
    return this.currentCall.getValue();
  }

  private startCodeAvailabilityCheck(): Observable<void> {
    return new Observable(observer => {
      if (this.getPharmacy() && this.getProgram()) {
        this.isLoading.next(true);

        this.programUtilityService
          .getNumberOfCodesAvailableToDispenseForPharmacyAndProgram(this.getPharmacy()!, this.getProgram()!)
          .pipe(finalize(() => this.isLoading.next(false)))
          .subscribe(codeAvailability => {
            this.checkCodeAvailability(codeAvailability);
            observer.next();
            observer.complete();
          });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  private checkCodeAvailability(codeAvailability: ProgramCodeAvailability): void {
    const $startingCopayCodeRequestHasCompleted = new Subject<void>();
    this.copayCodeRequestStateService.$starting
      .pipe(takeUntil(this.serviceDestroyed))
      .pipe(takeUntil($startingCopayCodeRequestHasCompleted))
      .subscribe(isStarting => {
        this.resetErrorMessage();
        this.setCodeAvailabilities(codeAvailability.codesLeftToday, codeAvailability.codesLeftThisWeek);

        if (!isStarting) {
          this.setErrorMessage(this.isPharmacyUnderCallLimit, codeAvailability.codesLeftToday > 0, codeAvailability.codesLeftThisWeek > 0);
          $startingCopayCodeRequestHasCompleted.next();
          $startingCopayCodeRequestHasCompleted.complete();
        }
      });
  }

  private setCodeAvailabilities(codesLeftToday: number, codesLeftThisWeek: number): void {
    this.numCodesAvailableToDispenseToday.next(codesLeftToday);
    this.numCodesAvailableToDispenseThisWeek.next(codesLeftThisWeek);

    const hasCodesAvailable = codesLeftToday > 0 && codesLeftThisWeek > 0;
    this.pharmacyHasAvailableCodes.next(hasCodesAvailable);
    this.isPharmacyBelowLimits.next(hasCodesAvailable);
  }

  get pharmacyHasAvailableCodesValue(): boolean {
    return this.pharmacyHasAvailableCodes.getValue();
  }

  setErrorMessage(hasCallsAvailable: boolean, hasDailyCodesAvailable: boolean, hasWeeklyCodesAvailable: boolean): void {
    if (!hasCallsAvailable && !hasDailyCodesAvailable && !hasWeeklyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.AllLimits);
    } else if (!hasCallsAvailable && !hasDailyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.CallAndDailyLimits);
      this.errorShort = CallServiceShortErrorMessage.DailyLimit;
    } else if (!hasCallsAvailable && !hasWeeklyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.CallAndWeeklyLimits);
    } else if (!hasDailyCodesAvailable && !hasWeeklyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.DailyAndWeeklyLimits);
      this.errorShort = CallServiceShortErrorMessage.WeeklyLimit;
    } else if (!hasWeeklyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.WeeklyLimit);
    } else if (!hasDailyCodesAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.DailyLimit);
      this.errorShort = CallServiceShortErrorMessage.WeeklyLimit;
    } else if (!hasCallsAvailable) {
      this.errorMessage.next(CallServiceErrorMessage.CallLimit);
      this.errorShort = CallServiceShortErrorMessage.DailyLimit;
    }

    this.callHasError.next(Utils.isNonEmptyString(this.getErrorMessage()));

    if (this.getProgram() && this.getProgram()?.is_weekly_batch_program === 1) {
      return;
    }

    if (!hasWeeklyCodesAvailable) {
      this.update({ request_failure: CopayCodeRequestFailures.WeeklyLimit });
    } else if (!hasDailyCodesAvailable) {
      this.update({ request_failure: CopayCodeRequestFailures.DailyLimit });
    }
  }

  private setLastCodeRequestWhenWeeklyBatchProgram(program: Program): void {
    if (program && program.is_weekly_batch_program && this.getPharmacy() && this.getPharmacy()?.ncpdp) {
      this.programUtilityService
        .getLastCodeRequestForPharmacyAndProgram(this.getPharmacy()!, program)
        .subscribe(codeRequest => this.lastCodeRequest.next(codeRequest));
    }
  }

  startCodeRequest() {
    if (this.getCurrentCall() && this.getCurrentCall()?.caller_id && !this.callHasError.getValue()) {
      this.isLoading.next(true);
      const codeRequestFields = {
        program_group: (this.getPharmacy() && this.getPharmacy()?.program_group) || this.tenantService.getCurrentTenantsDefaultGroupId(),
      };

      this.copayCodeRequestStateService
        .start('Call', this.getCurrentCall()?.caller_id ?? '', this.currentCall.getValue()?.call_start, codeRequestFields)
        .pipe(finalize(() => this.isLoading.next(false)))
        .subscribe((copayCodeRequest: ICopayCodeRequest) => {
          this.copayCodeRequest = copayCodeRequest;

          if (this.getPharmacy() && this.pharmacyUtilityService.isPharmacyBlocked(this.getPharmacy()!)) {
            this.copayCodeRequest.blocked = 1;
          }
        });
    }
  }

  resetErrorMessage(): void {
    this.errorMessage.next(undefined);
  }

  update(codeRequestFields?: Partial<ICopayCodeRequest>): void {
    const $startingCopayCodeRequestHasCompleted = new Subject<void>();
    this.copayCodeRequestStateService.$starting
      .pipe(takeUntil(this.serviceDestroyed))
      .pipe(takeUntil($startingCopayCodeRequestHasCompleted))
      .subscribe(() => {
        if (!this.copayCodeRequestStateService.isStarting) {
          this.isLoading.next(true);

          if (!codeRequestFields) {
            codeRequestFields = {} as Partial<ICopayCodeRequest>;
          }
          const _codeRequestFields = Utils.copyObject(this.copayCodeRequest) as ICopayCodeRequest;
          Object.assign(_codeRequestFields, codeRequestFields);

          _codeRequestFields.program = this.program.getValue()?.copay_program_id ?? '';
          _codeRequestFields.program_group = this.getPharmacy()?.program_group ?? '';
          _codeRequestFields.call_start = this.currentCall.getValue()?.call_start ?? new Date();

          if (this.shouldUseBlockedProgramId()) {
            _codeRequestFields.program = ProgramUtilityService.BLOCKED_PROGRAM_ID;
            _codeRequestFields.blocked = 1;
          }

          this.copayCodeRequestStateService
            .update(_codeRequestFields)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe(codeRequest => (this.copayCodeRequest = codeRequest));

          $startingCopayCodeRequestHasCompleted.next();
          $startingCopayCodeRequestHasCompleted.complete();
        }
      });
  }

  private shouldUseBlockedProgramId(): boolean {
    return this.isPharmacyNew(this.getPharmacy()) || this.pharmacyUtilityService.isPharmacyBlocked(this.getPharmacy());
  }

  dispenseSingleRequestCode(codeRequestFields: Partial<ICopayCodeRequest>) {
    this.isLoading.next(true);

    const _codeRequestFields = Utils.copyObject(this.copayCodeRequest) as ICopayCodeRequest;
    Object.assign(_codeRequestFields, codeRequestFields);

    _codeRequestFields.program = this.program.getValue()?.copay_program_id ?? '';
    _codeRequestFields.program_group = this.getPharmacy()?.program_group ?? '';
    _codeRequestFields.call_start = this.currentCall.getValue()?.call_start ?? new Date();

    this.copayCodeRequestStateService
      .dispense('Agent', _codeRequestFields)
      .pipe(finalize(() => this.isLoading.next(false)))
      .subscribe(
        (codeRequest: ICopayCodeRequest) => {
          this.copayCodeRequest = codeRequest;
          this.setCopayInformation();
        },
        error => {
          this.errorMessage.next(CallServiceErrorMessage.CodeDispense);
          this.Log.errorWithAction('Could not dispense code', 'agent-code-dispense', {
            extraInfo: { codeRequestFields: JSON.stringify(_codeRequestFields) },
          });
        },
      );
  }

  private setCopayInformation(): void {
    if (!this.copayCodeRequest) {
      return;
    }
    const copayInfo = {} as ICopayInformation;

    if ('copay_code_bin' in this.copayCodeRequest) {
      copayInfo.copay_code_bin = this.copayCodeRequest.copay_code_bin;
    }
    if ('copay_code_pcn' in this.copayCodeRequest) {
      copayInfo.copay_code_pcn = this.copayCodeRequest.copay_code_pcn;
    }
    if ('copay_code_group' in this.copayCodeRequest) {
      copayInfo.copay_code_group = this.copayCodeRequest.copay_code_group;
    }
    if ('dispensed_codes' in this.copayCodeRequest) {
      copayInfo.dispensed_codes = this.copayCodeRequest.dispensed_codes ?? [];
    }

    this.copayInfo.next(copayInfo);
  }

  dispenseBatchCodes() {
    this.isLoading.next(true);
    this.wasLastRequestAResend = false;

    this.copayCodeRequestStateService
      .dispense('Agent', this.getCodeRequestForWeeklyBatchDispense(false), this.getWeeklyBatchRequestStatus(false))
      .pipe(
        finalize(() => {
          this.startCodeAvailabilityCheck().subscribe();
          this.isLoading.next(false);
        }),
      )
      .subscribe((codeRequestResponse: ICopayCodeRequest) => {
        this.copayCodeRequest = codeRequestResponse;
        this.lastCodeRequest.next(this.copayCodeRequest);
      });
  }

  redipenseBatchCodes(): Observable<void> {
    this.isLoading.next(true);
    this.wasLastRequestAResend = true;

    return this.copayCodeRequestStateService.redispense({ program: this.getProgram()?.copay_program_id }).pipe(
      finalize(() => {
        this.copayCodeRequestApiService
          .updateCodeRequest(this.getCodeRequestForWeeklyBatchDispense(true))
          .pipe(
            catchError(error => {
              this.didResendHaveError = true;
              return of(error);
            }),
          )
          .pipe(finalize(() => this.isLoading.next(false)))
          .subscribe(_ => {});
      }),
    );
  }

  private getWeeklyBatchRequestStatus(isResend = false): CopayCodeRequestStatus {
    return isResend ? 'Resending' : 'Dispensing';
  }

  getCodeRequestForWeeklyBatchDispense(isResend = false): Partial<ICopayCodeRequest> {
    const _copayCodeRequest = Utils.copyObject(isResend ? this.lastCodeRequest.getValue() : this.copayCodeRequest) as ICopayCodeRequest;
    _copayCodeRequest.program = this.getProgram()?.copay_program_id ?? '';
    _copayCodeRequest.program_group = this.getPharmacy()?.program_group ?? '';
    if (this.getCurrentCall() && this.getCurrentCall()?.caller_id) {
      _copayCodeRequest.ncpdp = this.getCurrentCall()?.caller_id ?? '';
    }
    if (isResend) {
      _copayCodeRequest.request_status = this.getWeeklyBatchRequestStatus(true);
    }
    return _copayCodeRequest;
  }

  completeCodeRequest(): void {
    if (!this.copayCodeRequest) {
      return;
    }

    this.isLoading.next(true);

    this.copayCodeRequest.request_status = 'Completed';

    this.copayCodeRequestStateService
      .complete(this.copayCodeRequest, true)
      .pipe(finalize(() => this.isLoading.next(false)))
      .subscribe(() => {
        this.lastCodeRequest.next(this.copayCodeRequest);
      });

    const _currentCall = Utils.copyObject(this.currentCall.getValue()) as CallInformation;
    _currentCall.end = moment().utc().toDate();
    this.callsApiService.end(_currentCall).subscribe(() => this.currentCall.next(_currentCall));
  }

  updateRx() {
    this.isLoading.next(true);
  }

  addPatient(updatePatientNumber = true, origin = ''): void {
    this.reset(false);
    // origin: used by new agent panel to decide which step to go to
    if (updatePatientNumber) {
      this.patientNumber.next(this.patientNumber.getValue() + 1);
    }

    this.startCodeRequest();
    this.startCodeAvailabilityCheck().subscribe();
  }

  private get isPharmacyUnderCallLimit(): boolean {
    return this.patientNumber.getValue() <= this.callLimit;
  }

  getErrorMessage(): string {
    return this.errorMessage.getValue() ?? '';
  }

  canResendWeeklyBatchCodes(): boolean {
    if (!this.getProgram() || !this.getProgram()?.is_weekly_batch_program || !this.lastCodeRequest.getValue()) {
      return false;
    }

    return this.numCodesAvailableToDispenseThisWeek.getValue() === 0 && Utils.castToBool(this.lastCodeRequest.getValue());
  }
}
