import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '@services/tenant.service';
import { TenantIdType } from '@shared/models/tenant-id.type';
import config from '@config';

@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.scss'],
})
export class TcComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantService: TenantService,
  ) {}

  ngOnInit() {
    this.loadTermsAndConditions();
  }

  loadTermsAndConditions() {
    this.activatedRoute.params.subscribe(params => {
      const tenantId = params['tenant'].toUpperCase() as TenantIdType;
      if (this.doesTenantHaveTC(tenantId)) {
        this.redirectToTermsAndCondition(tenantId);
      } else {
        this.redirectToLogin();
      }
    });
  }

  doesTenantHaveTC(tenantId: TenantIdType) {
    return config.termsAndConditions.includes(tenantId);
  }

  redirectToTermsAndCondition(tenantId: string) {
    this.document.location.href = `docs/${tenantId.toLowerCase()}/tc.pdf`;
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }
}
