import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NEW_ROUTES } from '@app/routes.module';
import config from '@config';
import { AppStateService } from '@services/app-state.service';
import { AuthService } from '@services/auth.service';
import { EtlRoutesStore } from '@services/etl-routes.store';
import { FeatureFlagsService } from '@services/feature-flags.service';
import { PharmaciesSearchStore } from '@services/pharmacies.search.store';
import { SettingsStore } from '@services/settings.store';
import { TenantService } from '@services/tenant.service';
import { UIAnalyticsService } from '@services/ui-analytics.service';
import { UserService } from '@services/user.service';
import { Amplify } from 'aws-amplify';
import { Observable, forkJoin, timer } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.main.html',
})
export class AppComponent {
  private isActiveWindow = true;
  private readonly stores = {
    etlRoutes: inject(EtlRoutesStore),
    pharmacies: inject(PharmaciesSearchStore),
    settings: inject(SettingsStore),
  };

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private tenantService: TenantService,
    private appStateService: AppStateService,
    private uiAnalyticsService: UIAnalyticsService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.initAmplify();
    this.trackAuthStatus();
    this.trackPageChange();
    this.trackWindowState();
    this.trackLocalStorage();
    this.trackStores();
    this.trackTenantChange();
  }

  private initAmplify() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.cognito.userpoolId,
          userPoolClientId: config.cognito.appClientId,
        },
      },
    });
  }

  private trackWindowState(): void {
    window.addEventListener('blur', () => {
      this.isActiveWindow = false;
    });
    window.addEventListener('focus', () => {
      this.isActiveWindow = true;
    });
  }

  private trackLocalStorage(): void {
    window.addEventListener('storage', event => {
      if (event.storageArea === localStorage) {
        const logout = localStorage.getItem(this.authService.LOGOUT_INITIATED);
        if (logout === this.authService.LOGOUT_INITIATED_VALUE && !this.isActiveWindow) {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  private trackPageChange(): void {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.router.url !== event.url) this.uiAnalyticsService.trackRedirect(event.url);
        this.checkVersion();
      } else if (event instanceof GuardsCheckEnd) {
        if (!event.shouldActivate) {
          this.authService.logout('AuthGuardCheck');
        }
      } else if (event instanceof NavigationEnd) {
        this.checkStyles(event);
      }
    });
  }

  private trackAuthStatus(): void {
    this.appStateService.resetApplicationState();
    this.authService.authStatusChanged.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe(authenticated => {
      if (authenticated) {
        const user = this.authService.getCurrentUser();
        this.appStateService.resetApplicationState(user);
        this.setTenantSpecificPages();
        if (!this.userService.hasAcceptedAgreements(user)) {
          this.router.navigate(['/agreement']);
        } else {
          this.authService.redirectToStartingPage();
        }
      }
    });
  }

  private async checkVersion() {
    await this.featureFlagsService.load({ skipCache: true });
    const apiVersion = this.featureFlagsService.getStringValue('app-version');
    const isVersionTheSame = apiVersion === config.version;
    if (!isVersionTheSame) {
      console.log('Version different. Refreshing');
      console.log('API version: ', apiVersion);
      console.log('ENV version: ', config.version);
      window.location.reload();
    }
  }

  private checkStyles(event: NavigationEnd) {
    const bodyEl = document.documentElement;
    const isNew = NEW_ROUTES.find(route => route.path && event.urlAfterRedirects.startsWith(route.path));
    if (isNew) {
      bodyEl.classList.add('bs');
      bodyEl.classList.remove('bs-old');
    } else {
      bodyEl.classList.add('bs-old');
      bodyEl.classList.remove('bs');
    }
  }

  private setTenantSpecificPages(): void {
    if (this.tenantService.usersTenants.length > 0) {
      this.tenantService.changeCurrentTenant(this.tenantService.usersTenants[0]);
    }
  }

  private trackTenantChange() {
    this.tenantService.activeTenantChanged.subscribe(() => {
      if (this.userService.isComponentAllowedForRoute(this.activatedRoute) === false) {
        this.authService.redirectToStartingPage();
      }
    });
  }

  private trackStores() {
    const fiveMinutes = 5 * 60 * 1000;
    timer(fiveMinutes, fiveMinutes)
      .pipe(
        switchMap(() => {
          const sources$: Array<Observable<{}>> = [];
          sources$.push(this.stores.etlRoutes.refresh());
          sources$.push(this.stores.pharmacies.refresh());
          sources$.push(this.stores.settings.refresh());
          return forkJoin(sources$);
        }),
      )
      .subscribe(() => {
        console.log('Stores refresh done');
      });
  }
}
