import { ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { TenantService } from '@services/tenant.service';

@Injectable()
export class TenantGuard {
  constructor(
    private authService: AuthService,
    private tenantService: TenantService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedTenant = route.data.expectedTenant;
    const currentTenant = this.tenantService.currentTenant;
    alert(currentTenant);
    return !!currentTenant && currentTenant.id.toLowerCase() === expectedTenant;
  }
}
