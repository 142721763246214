import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AgreementForm } from '@app/root/components/agreement/agreement.form';
import { LoginLayoutComponent } from '@app/root/components/login-layout/login-layout.component';
import { FormlyModule } from '@ngx-formly/core';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { createFormGroup } from '@shared/components/forms/form';
import { AgreementType } from '@shared/models/agreement-type.enum';
import { IUser } from '@shared/models/user.model';

@Component({
  selector: 'app-agreement',
  standalone: true,
  imports: [LoginLayoutComponent, FormlyModule, ReactiveFormsModule],
  templateUrl: './agreement.component.html',
})
export class AgreementComponent {
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private router = inject(Router);

  form = signal(createFormGroup<IUser>());
  formFields = inject(AgreementForm).create();
  user = signal<IUser | null>(null);
  error = signal('');
  isSending = signal(false);

  constructor() {
    if (this.userService.isUserAuthenticated()) {
      const user = this.authService.getCurrentUser();
      this.user.set(user);
      if (this.userService.hasAcceptedAgreements(user)) this.authService.redirectToStartingPage();
    } else {
      this.router.navigate(['/login']);
    }
  }

  onSubmit() {
    let user = this.user();
    if (!user || this.form().invalid) return;
    this.isSending.set(true);
    user = Object.assign(user, this.form().value);
    user = this.userService.acceptAgreement(user, AgreementType.USER_PHARMACY_OWNER);
    this.userService
      .updateCurrentUser(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isSending.set(false);
          this.authService.redirectToStartingPage();
        },
        error: (err: HttpErrorResponse) => {
          this.isSending.set(false);
          this.error.set(err.error);
        },
      });
  }
}
