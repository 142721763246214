import { PasswordComplexity } from '@shared/models/password-complexity.enum';

export class PasswordValidator {
  readonly errorsMap: Record<PasswordComplexity, string> = {
    [PasswordComplexity.TOO_SHORT]: '8 characters minimum',
    [PasswordComplexity.NO_LOWERCASE_CHARACTER]: 'One lowercase character',
    [PasswordComplexity.NO_UPPERCASE_CHARACTER]: 'One uppercase character',
    [PasswordComplexity.NO_NUMERIC_CHARACTER]: 'One number',
    [PasswordComplexity.NO_SPECIAL_CHARACTER]: 'One special (symbol) character',
  };

  constructor(public readonly value: string) {}

  isComplex(): PasswordComplexity[] {
    const MINIMUM_PASSWORD_LENGTH = 8;
    // At least one LOWERCASE character:
    const LOWERCASE_PATTERN = /^(?=.*[a-z]).+$/;
    // At least one UPPERCASE character:
    const UPPERCASE_PATTERN = /^(?=.*[A-Z]).+$/;
    // At least one SPECIAL character:
    const SPECIAL_CHARACTER_PATTERN = /([-+=_!@#$%^&*.,;:'"<>/?`~[\](){}\\|\s])/;
    // At least one number:
    const NUMERIC_CHARACTER_PATTERN = /^(?=.*[0-9]).+$/;

    const complexity = [];

    if (this.value.length < MINIMUM_PASSWORD_LENGTH) {
      complexity.push(PasswordComplexity.TOO_SHORT);
    }
    if (!this.value.match(LOWERCASE_PATTERN)) {
      complexity.push(PasswordComplexity.NO_LOWERCASE_CHARACTER);
    }
    if (!this.value.match(UPPERCASE_PATTERN)) {
      complexity.push(PasswordComplexity.NO_UPPERCASE_CHARACTER);
    }
    if (!this.value.match(NUMERIC_CHARACTER_PATTERN)) {
      complexity.push(PasswordComplexity.NO_NUMERIC_CHARACTER);
    }
    if (!this.value.match(SPECIAL_CHARACTER_PATTERN)) {
      complexity.push(PasswordComplexity.NO_SPECIAL_CHARACTER);
    }

    return complexity;
  }

  validate(passwordCheck: string) {
    const validations = this.isComplex().map(item => this.errorsMap[item]);

    if (!passwordCheck || this.value !== passwordCheck) {
      validations.push('The passwords entered must match');
    }

    return validations;
  }
}
