<div class="dashboard body_img support-container">
  <div class="row pb-5">
    <div class="col-md-12">
      <h5>Support</h5>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-md-6">
      <p class="font-14">
        Please call for live agent support. For any program related questions or concerns outside the request of codes, please contact your
        Horizon representative directly or email us below.
      </p>
    </div>
  </div>

  <div class="row support-container__info-container">
    <div class="col-auto d-inline-flex">
      <i class="material-icons support-container__contact-icon"> phone </i>
    </div>
    <div class="col pl-0">
      <p class="mb-0"><a class="support-container__contact-info" href="tel:+1-844-492-2731">(844) 492-2731</a></p>
    </div>
  </div>

  <div class="row support-container__info-container">
    <div class="col-auto d-inline-flex">
      <i class="material-icons support-container__contact-icon"> email </i>
    </div>
    <div class="col pl-0">
      <p class="mb-0">
        <a class="support-container__contact-info" href="mailto:HorizonCaresInfo@horizontherapeutics.com"
          >HorizonCaresInfo&#64;horizontherapeutics.com</a
        >
      </p>
    </div>
  </div>
</div>
