import { Injectable } from '@angular/core';
import { AnalyticsParams } from '@shared/models/analytics/analytics-params.model';
import { Analytics } from '@shared/models/analytics/analytics.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { DataExtractModel } from '@shared/models/data-extract.model';
import { IPharmacy } from '@shared/models/pharmacy.model';

@Injectable()
export class AnalyticsService {
  static readonly ALL_STATES_TEXT = 'All States';
  static readonly ALL_PHARMACIES_TEXT = 'All Pharmacies';
  static readonly ALL_NETWORK_GROUPS_TEXT = 'All Network Groups';

  refresh = new Subject<void>();
  dataExtractChange = new Subject<DataExtractModel>();
  networkGroups = new BehaviorSubject<string[]>([]);
  state = new BehaviorSubject<string>('');
  pharmacy = new BehaviorSubject<IPharmacy | undefined>(undefined);

  getAnalyticsSummary(params?: AnalyticsParams): Analytics {
    const analytics: Analytics = new Analytics();

    analytics.totalEncounters = this.getRandomNumber(50, 250);
    analytics.averageTime = this.getRandomNumber(10, 30);
    analytics.ratioSuccess = this.getRandomNumber(25, 50);
    analytics.ratioFailed = this.getRandomNumber(25, 50);
    analytics.ratioAdjudicated = this.getRandomNumber(25, 50);

    return analytics;
  }

  private getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
}
