import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i2 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i1 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
function FormlyFieldTextArea_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "textarea", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("is-invalid", ctx_r0.showError);
    i0.ɵɵproperty("formControl", ctx_r0.formControl)("cols", ctx_r0.props.cols)("rows", ctx_r0.props.rows)("formlyAttributes", ctx_r0.field);
    i0.ɵɵattribute("aria-describedby", ctx_r0.id + "-formly-validation-error")("aria-invalid", ctx_r0.showError);
  }
}
class FormlyFieldTextArea extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        cols: 1,
        rows: 1
      }
    };
  }
}
FormlyFieldTextArea.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldTextArea_BaseFactory;
  return function FormlyFieldTextArea_Factory(t) {
    return (ɵFormlyFieldTextArea_BaseFactory || (ɵFormlyFieldTextArea_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldTextArea)))(t || FormlyFieldTextArea);
  };
})();
FormlyFieldTextArea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldTextArea,
  selectors: [["formly-field-textarea"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], [1, "form-control", 3, "formControl", "cols", "rows", "formlyAttributes"]],
  template: function FormlyFieldTextArea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldTextArea_ng_template_0_Template, 1, 8, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DefaultValueAccessor, i1.NgControlStatus, i1.FormControlDirective, i2.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldTextArea, [{
    type: Component,
    args: [{
      selector: 'formly-field-textarea',
      template: `
    <ng-template #fieldTypeTemplate>
      <textarea
        [formControl]="formControl"
        [cols]="props.cols"
        [rows]="props.rows"
        class="form-control"
        [class.is-invalid]="showError"
        [formlyAttributes]="field"
        [attr.aria-describedby]="id + '-formly-validation-error'"
        [attr.aria-invalid]="showError"
      ></textarea>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyBootstrapTextAreaModule {}
FormlyBootstrapTextAreaModule.ɵfac = function FormlyBootstrapTextAreaModule_Factory(t) {
  return new (t || FormlyBootstrapTextAreaModule)();
};
FormlyBootstrapTextAreaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapTextAreaModule
});
FormlyBootstrapTextAreaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapTextAreaModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldTextArea],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapTextAreaModule, FormlyFieldTextArea };
