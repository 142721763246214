import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginLayoutComponent } from '@app/root/components/login-layout/login-layout.component';
import { AuthService } from '@services/auth.service';
import { PasswordValidator } from '@shared/models/password.model';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-pass-form',
  standalone: true,
  imports: [FormsModule, SharedModule, LoginLayoutComponent],
  templateUrl: './pass-form.component.html',
})
export class PassFormComponent {
  readonly authService = inject(AuthService);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly router = inject(Router);

  send = output<string>();
  isSending = input(false);
  isReset = input(false);
  validations = signal<string[]>([]);
  password = signal('');
  passwordCheck = signal('');
  passwordType = computed(() => (this.showPassword() ? 'text' : 'password'));
  showPassword = signal(false);

  constructor() {
    effect(
      () => {
        this.password() && this.passwordCheck();
        this.validate();
      },
      { allowSignalWrites: true },
    );
  }

  validate() {
    const validator = new PasswordValidator(this.password());
    this.validations.set(validator.validate(this.passwordCheck()));
  }

  onSubmit() {
    if (this.validations().length) return;
    this.send.emit(this.password());
  }
}
