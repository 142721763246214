import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { RoutesExplorer } from '@app/router-explorer';
import config from '@config';
import { DateUtility } from '@shared/date-utility';
import 'leaflet';
import 'leaflet.markercluster';

DateUtility.MILLISECONDS_FIXED_DATE = config.fixedDateMs;

if (config.production) {
  enableProdMode();
} else {
  console.log(RoutesExplorer);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
