import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { TenantService } from '@services/tenant.service';

@Injectable({ providedIn: 'root' })
export class HttpTenantInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenantService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentTenant = this.tenantService.currentTenant;
    if (currentTenant && currentTenant.id) {
      request = request.clone({ url: request.url.replace('{tenant}', currentTenant.id.toUpperCase()) });
    } else if (request.url.includes('{tenant}')) {
      // if we don't have a tenant then cancel the request.
      console.log('Canceling request due to no tenant');
      return EMPTY;
    }

    return next.handle(request);
  }
}
