<app-login-layout [header]="showReset() ? 'Password reset' : 'Welcome to brightscrip™'">
  @if (error()) {
    <div class="alert alert-danger col-10 mt-4" role="alert" data-cy="alert">
      <div class="alert-message"><strong class="pe-1">There's been an issue: </strong>{{ error() }}</div>
    </div>
  }

  @if (success()) {
    <div class="alert alert-success col-10 mt-4" role="alert" data-cy="alert">
      <div class="alert-message"><strong class="pe-1">Success! </strong>{{ success() }}</div>
    </div>
  }

  @if (showConfirm()) {
    <app-pass-form (send)="confirmPass($event)" [isSending]="isSending()" />
  } @else if (showReset()) {
    <app-pass-form (send)="resetPass($event)" [isSending]="isSending()" />

    <a routerLink="/login" (click)="showReset.set(false)" class="mt-4 d-block forgot-password-link" data-cy="forgotPassword">
      Back to login
    </a>
  } @else {
    <form (ngSubmit)="onLogin()">
      <div class="mt-4 mb-3 login-field">
        <label class="form-label" for="username">Username</label>
        <input type="text" [(ngModel)]="username" name="username" id="username" required class="form-control" />
      </div>
      <div class="mb-4 login-field">
        <label class="form-label" for="password">Password</label>
        <input type="password" [(ngModel)]="password" name="password" id="password" required class="form-control" />
      </div>
      <chitin-button type="submit" data-cy="loginBtn" label="Sign in" [isLoading]="isSending()" />
    </form>

    <a routerLink="/login/forgot" class="mt-4 d-block forgot-password-link" data-cy="forgotPassword">Forgot password?</a>

    @if (contactFormEnabled()) {
      <a class="mt-2 d-block forgot-password-link" (click)="showContactForm()" data-cy="contactUs">Questions or need support?</a>
    }
  }
</app-login-layout>
