<div class="page-wrapper">
  <div class="login-form">
    <img class="logo d-block" src="/assets/logo.png" />
    <h1 class="text-center text-md-start mb-3">{{ header() }}</h1>
    <ng-content></ng-content>
  </div>
  <div class="info-panel bg-light">
    <!-- <app-announcement-panel /> -->
  </div>
</div>
