<!--<div class="full-width full-height {{ theme }}">-->
<!--  <app-header *ngIf="isAuthenticated"></app-header>-->
<!--  <div class="row no-gutters full-height">-->
<!--    <div *ngIf="isAuthenticated && showNavigation" class="navigation-container" [class.navigation-container&#45;&#45;open]="!isNavCollapsed">-->
<!--      <navigation></navigation>-->
<!--    </div>-->
<!--    <div-->
<!--      class="body-content col-12"-->
<!--      [class.col-lg-10]="showNavigation && !isNavCollapsed"-->
<!--      [class.col-lg-12]="!showNavigation"-->
<!--      [class.body-content&#45;&#45;full-width]="!showNavigation"-->
<!--      [class.nav-collapsed]="showNavigation && isNavCollapsed"-->
<!--      [class.body-content&#45;&#45;default-header]="(isDefaultHeader || isNoneHeader) && !isTokenLogin()"-->
<!--      [class.body-content&#45;&#45;pba-header]="isTokenLogin()"-->
<!--      [class.mt-0]="isExpiredLinkPage()"-->
<!--    >-->
<!--      <router-outlet></router-outlet>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<router-outlet></router-outlet>

<app-toasts></app-toasts>
