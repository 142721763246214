import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LoginLayoutComponent } from '@app/root/components/login-layout/login-layout.component';
import { PassFormComponent } from '@app/root/components/pass-form/pass-form.component';
import { AuthService } from '@services/auth.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-pass-forgot',
  standalone: true,
  imports: [FormsModule, SharedModule, LoginLayoutComponent, PassFormComponent],
  templateUrl: './pass-forgot.component.html',
})
export class PassForgotComponent {
  readonly authService = inject(AuthService);
  readonly destroyRef = inject(DestroyRef);

  error = signal('');
  success = signal(false);
  isSending = signal(false);
  username = signal('');

  onSend() {
    this.error.set('');
    this.isSending.set(true);
    this.authService
      .forgotPassword(this.username())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.success.set(true);
          this.isSending.set(false);
        },
        error: (err: HttpErrorResponse) => {
          this.error.set(err.error);
          this.isSending.set(false);
        },
      });
  }
}
